exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2017-08-13-nginx-test-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2017-08-13--nginx-test/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2017-08-13-nginx-test-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2017-08-14-plan-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2017-08-14--plan/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2017-08-14-plan-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2017-08-22-electron-react-redux-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2017-08-22--Electron+react+redux/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2017-08-22-electron-react-redux-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2017-11-02-new-computer-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2017-11-02--new-computer/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2017-11-02-new-computer-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2018-09-01-first-post-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2018-09-01--first-post/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2018-09-01-first-post-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2018-10-08-manjaro-config-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2018-10-08--manjaro_config/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2018-10-08-manjaro-config-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2018-10-23-chopin-op-10-no-1-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2018-10-23--chopin-OP10NO1/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2018-10-23-chopin-op-10-no-1-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2019-02-10-keyboard-layout-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2019-02-10--keyboard-layout/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2019-02-10-keyboard-layout-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2019-04-14-corne-keyboard-build-log-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2019-04-14--corne_keyboard_build_log/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2019-04-14-corne-keyboard-build-log-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2019-11-10-bluetooth-keyboard-build-log-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2019-11-10--bluetooth_keyboard_build_log/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2019-11-10-bluetooth-keyboard-build-log-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2024-01-31-blog-tech-stack-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2024-01-31--blog-tech-stack/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2024-01-31-blog-tech-stack-index-mdx" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

